import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { SnackbarService } from 'src/app/core/snackBar.notification.service';
import { SpinnerService } from 'src/app/core/spinner.service';
import { ProfileDetails } from 'src/app/models/profileDetails.model';
import { Util } from 'src/app/util/Util';

import { CopyProfileDialogComponent } from '../copy-profile-dialog/copy-profile-dialog.component';
import { ProfileService } from '../services/profile.service';
import { UserProfileService } from '@services/userProfile.service';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', border: '2px' },
    },
  ],
})
export class ManageProfileComponent implements OnInit, AfterViewInit {
  // displayedColumns: string[] = ['select', 'profileID', 'profileName','internalNotes', 'countryName', 'lastModifiedBy','actions'];
  displayedColumns: string[] = [
    'select',
    'profileName',
    'internalNotes',
    'countryName',
    'createdBy',
    'createdDate',
    'lastModifiedDate',
    'favorite',
    'status',
    'copy',
    'edit',
  ];
  dataSource: MatTableDataSource<ProfileDetails>;

  profiles: ProfileDetails[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) matTable: MatTable<any>;

  selection = new SelectionModel<ProfileDetails>(true, []);

  disableIcon = faBan;

  paginatorSize = 10;

  constructor(
    private profileService: ProfileService,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private router: Router,
    private snackBarService: SnackbarService,
    private util: Util,
    public userProfileService: UserProfileService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cd.detectChanges();

  }

  applySort() {
    const sortState: Sort = { active: 'profileName', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;

    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: ProfileDetails, filter: string) => {

      const profileNameMatch = data.profileName.toLowerCase().includes(filter);
      const internalNotesMatch = data.internalNotes?.toLowerCase().includes(filter);
      const countryNameMatch = data.countryName?.toLowerCase().includes(filter);
      const createdByMatch = data.createdBy?.toLowerCase().includes(filter);

      return profileNameMatch || internalNotesMatch || countryNameMatch || createdByMatch;
     };

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clearSearch(event: Event) {
    this.dataSource.filter = '';
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { profiles: any }) => {
      this.spinnerService.hide();
      this.dataSource = new MatTableDataSource(data.profiles);
      this.profiles = data.profiles;
    });

    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'favorite') {
        return item.defaultProfile;
      } else if (property === 'status') {
        return item.active;
      } else if (property === 'createdDate') {
        return new Date(item.createdDate);
      } else if (property === 'lastModifiedDate') {
        return new Date(item.lastModifiedDate);
      } else {
        return item[property];
      }
    };
  }

  goToPage(event: any) {
    this.paginatorSize = event.pageSize;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: ProfileDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.profileID + 1}`;
  }

  copyProfile(row: ProfileDetails) {
    const dialogRef = this.showCopyProfileDialog();

    dialogRef.afterClosed().subscribe((copyObject) => {
      if (copyObject) {
        // check if external profile name entered by user already exists
        const existingProfileNames = this.dataSource.data.map((profile) => profile.profileName);
        const extName = copyObject.externalProfileName;

        const indexAt = existingProfileNames.findIndex((profile) => profile.toLowerCase() == extName.toLowerCase());
        if (indexAt != -1) {
          this.snackBarService.showError("Pofile '" + extName + "' already exists. Please try a different name.");
          return;
        }

        this.spinnerService.show(' Copying profile...');

        let intName = copyObject.internalProfileName;
        const visibleInMultisite = this.getVisibilityFlags(row.visibleInMultiSite);
        const visibleInBenchmark = this.getVisibilityFlags(row.visibleInBenchmark);
        const visibleInGeoskill = this.getVisibilityFlags(row.visibleInGeoskill);
        if (copyObject.sameAsInternalName) {
          intName = extName;
        }
        this.profileService
          .saveProfile(
            null,
            row.profileDatapoints,
            extName,
            intName,
            row.defaultProfile,
            row.countryCodes,
            row.active,
            visibleInBenchmark,
            visibleInMultisite,
            visibleInGeoskill,
            row.clientIds
          )
          .subscribe(
            (response) => {
              this.spinnerService.hide();
              this.snackBarService.showInformation("Profile '" + extName + "' has been created successfully! ");
              const newProfile = response;
              const tempList = this.dataSource.data;
              tempList.push(newProfile);
              this.dataSource.data = tempList;
              this.matTable.renderRows();
              this.applySort();
            },
            (error) => {
              console.error('Drive-Time: Profilewizard -> saveProfile -> error', error);
              this.spinnerService.hide();
              this.snackBarService.showError(
                "Profile '" + row.profileName + "' could not be copied!. Please reach out to support team. ",
              );
            },
          );
      } else {
        // console.log("Cancel button was clicked... Do nothing")
      }
    });
  }

  getVisibilityFlags(flag: any) {
    if (flag === null || flag === undefined) {
      return false;
    } else {
      return flag;
    }
  }

  setFavorite(row: ProfileDetails) {
    const favorite = !row.defaultProfile;
    const profileIds = [];
    profileIds.push(row.profileID);
    if (favorite) {
      this.spinnerService.show(' Setting profile as favorite...');
    } else {
      this.spinnerService.show(' Unsetting profile as favorite...');
    }
    this.profileService.updateFavorite(favorite, profileIds).subscribe(
      (response) => {
        this.spinnerService.hide();
        if (favorite) {
          this.snackBarService.showInformation("Profile '" + row.profileName + "' is now set as favorite.");
        } else {
          this.snackBarService.showInformation("Profile '" + row.profileName + "' is now not a favorite.");
        }

        row.defaultProfile = favorite;
      },
      (error) => {
        console.error('Drive-Time: ManageProfiles -> setFavorite -> error', error);
        this.spinnerService.hide();
        if (favorite) {
          this.snackBarService.showError(
            "Profile '" + row.profileName + "' couldn't be set as favorite. Please contact system support team.",
          );
        } else {
          this.snackBarService.showError(
            "Profile '" + row.profileName + "' couldn't be unset as favorite.. Please contact system support team.",
          );
        }
      },
    );
  }

  setBulkFavorites(favorite: boolean) {
    if (this.selection.selected.length > 0) {
      if (!this.checkIfCallNeeded('defaultProfile', favorite)) return; // exit out if all selected profiles are already favorites.

      const profileIds = this.selection.selected.map((profile) => profile.profileID);
      this.profileService.updateFavorite(favorite, profileIds).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.snackBarService.showInformation('Selected profile(s) favorite status is updated.');
          this.selection.selected.forEach((profile) => {
            profile.defaultProfile = favorite;
          });
          // deselect each row that was selected...
          this.selection.selected.forEach((profile) => {
            this.selection.deselect(profile);
          });
        },
        (error) => {
          console.error('Drive-Time: ManageProfiles -> setBulkFavorites -> error', error);
          this.spinnerService.hide();
          this.snackBarService.showError(
            "Selected profile(s) favorite status couldn't be updated. Please contact system support team.",
          );
        },
      );
    } else {
      // this.dialogService.showWarningDialog("Warning","No profile is selected. Please select profile(s) and retry.")
      this.snackBarService.showWarning('No profile is selected. Please select profile(s) and retry.');
    }
  }

  setBulkEnableDisable(status: boolean) {
    if (this.selection.selected.length > 0) {
      if (!this.checkIfCallNeeded('active', status)) return; // exit out if all selected profiles are in the required status.

      const profileIds = this.selection.selected.map((profile) => profile.profileID);
      let text = 'disabled';
      if (status) {
        text = 'enabled';
      }
      this.profileService.updateStatus(status, profileIds).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.snackBarService.showInformation('Selected profile(s) have been ' + text + '.');
          this.selection.selected.forEach((profile) => {
            profile.active = status;
          });
          // deselect each row that was selected...
          this.selection.selected.forEach((profile) => {
            this.selection.deselect(profile);
          });
        },
        (error) => {
          console.error('Drive-Time: ManageProfiles -> setBulkEnableDisable -> error', error);
          this.spinnerService.hide();
          this.snackBarService.showError(
            "Selected profile(s) couldn't be " + text + '. Please contact system support team.',
          );
        },
      );
    } else {
      // this.dialogService.showWarningDialog("Warning","No profile is selected. Please select profile(s) and retry.")
      this.snackBarService.showWarning('No profile is selected. Please select profile(s) and retry.');
    }
  }

  bulkDelete() {
    if (this.selection.selected.length > 0) {
      const profileIds = this.selection.selected.map((profile) => profile.profileID);

      this.profileService.deleteProfiles(profileIds).subscribe(
        (response) => {
          this.spinnerService.hide();
          this.snackBarService.showInformation('Selected profile(s) have been deleted.');
          // remove the profiles from the grid that are delted by user..
          const tempList = this.dataSource.data.filter((profile) => !this.selection.selected.includes(profile));
          this.dataSource.data = tempList;
          // also remove the profiles deleted from profiles list present in user session
          this.profiles = this.profiles.filter((profile) => !this.selection.selected.includes(profile));
          // clear the selection in the grid
          this.selection.clear();
        },
        (error) => {
          console.error('Drive-Time: ManageProfiles -> bulkDelete -> error', error);
          this.spinnerService.hide();
          this.snackBarService.showError(
            "Selected profile(s) couldn't be deleted. Please contact system support team.",
          );
        },
      );
    } else {
      // this.dialogService.showWarningDialog("Warning","No profile is selected. Please select profile(s) and retry.")
      this.snackBarService.showWarning('No profile is selected. Please select profile(s) and retry.');
    }
  }

  setProfileStatus(row: ProfileDetails) {
    const status = !row.active;
    const profileIds = [];
    profileIds.push(row.profileID);
    if (status) {
      this.spinnerService.show(' Enabling the profile...');
    } else {
      this.spinnerService.show(' Disabling the profile...');
    }
    this.profileService.updateStatus(status, profileIds).subscribe(
      (response) => {
        this.spinnerService.hide();
        if (status) {
          this.snackBarService.showInformation("Profile '" + row.profileName + "' is now enabled.");
        } else {
          this.snackBarService.showInformation("Profile '" + row.profileName + "' is now disabled.");
        }
        row.active = status;
      },
      (error) => {
        console.error('Drive-Time: ManageProfiles -> setProfileStatus -> error', error);
        this.spinnerService.hide();
        if (status) {
          this.snackBarService.showInformation("Profile '" + row.profileName + "' couldn't be enabled.");
        } else {
          this.snackBarService.showInformation("Profile '" + row.profileName + "' couldn't be disabled.");
        }
      },
    );
  }

  checkIfCallNeeded(propperty: string, expValue: boolean) {
    return this.selection.selected.filter((profile) => profile[propperty] !== expValue).length > 0;
  }

  showCopyProfileDialog() {
    const dialogRef = this.matDialog.open(CopyProfileDialogComponent, {
      disableClose: true,
      panelClass: 'copyProfileDialog',
      // minHeight: 'calc(100vh - 90px)',
      minHeight: '30vh',
      data: { headerText: 'COPY PROFILE' },
    });

    return dialogRef;
  }

  editProfile(row: ProfileDetails) {
    this.router.navigate(['/profile/editprofile/' + row.profileID]);
  }
}
