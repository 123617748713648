<div class="dictionary">
    <div class="dictionary-container d-flex emeraldstandard">
        <div style="max-width: 956px;">
            <div class="headline-h3">Data Dictionary</div>
            <p><i>Benchmark & Multisite</i></p>
            <div class="content-section">
                <div class="dictionary-content" id="BureauLabor">
                    <h4 style="margin-top: 24px;"> <a href="https://www.bls.gov/oes/tables.htm" target="_self">Bureau of
                            Labor Statistics (BLS)</a></h4>
                    <p>Geography Type: United States (National & Metro Level)</p>
                    <p>GeoNavigator™ Profile Categories: Labor Supply</p>
                    <p>Data Variables:</p>
                    <ul>
                        <li>
                            Occupational Count – The employment estimates for approximately 830 occupations on the
                            national, state, and metro/nonmetropolitan areas.
                        </li>
                        <li>
                            Occupational Density - The employment estimates per 10,000 workers for approximately 830
                            occupations on the national, state, and metro/nonmetropolitan areas.
                        </li>
                        <li>
                            Unemployment Rate (Monthly, Annual, 5 Year) - The percentage of people in the labor force
                            who are unemployed, seasonally adjusted. The unemployment rate is calculated by the number
                            of people unemployed divided by the labor force and multiplied by 100.
                        </li>
                    </ul>
                    <p>Data Release Frequency: Annually, <i>Unemployment Rate Monthly via API</i>
                    </p>
                    <p>Year of the Data: 2023</p>
                    <p>Additional Information: <a href="https://www.bls.gov/ooh/about/glossary.htm"
                            target="_self">Occupational Handbook</a></p>
                </div>
                <div class="dictionary-content" id="EasyAnalytics">
                    <h4 style="margin-top: 24px;"><a href="https://www.easidemographics.com/web/" target="_self">Easy
                            Analytics Software Inc (EASI)</a></h4>
                    <p>Geography Type: United States (Metro Level)</p>
                    <p>GeoNavigator™ Profile Categories: Business Environment</p>
                    <p>Data Variables:</p>
                    <ul>
                        <li>
                            Crime Index - A combination of 8 crime related variables: murder, forcible rape, robbery,
                            aggravated assault, burglary, larceny, motor theft and arson (US Average=100) – with more
                            weight given to violent crimes. The higher the value, the more crime there is.
                        </li>
                        <li>
                            Quality of Life - A combination measure (US Average Index=100) developed from 29 different
                            quality of life variables (weather index, crime index, culture index, amusement index,
                            restaurant index, medical index, religion index, education index…). Weights were assigned
                            based upon each variable's importance (EASI developed) to a high quality of life. The higher
                            the value, the better the quality.
                        </li>
                        <li>
                            Weather Index - A combination measure (US Average=100) developed from 10 different weather
                            variables: average temperature, average annual heating, average annual cooling, percent of
                            possible sunshine, mean sky cover, mean number of clear days, mean range of temperature,
                            mean number of days snow, average annual precipitation, and average annual snowfall. The
                            higher the value, the better the weather (not too hot, not cold).
                        </li>
                    </ul>
                    <p>Data Release Frequency: Annually</p>
                    <p>Year of the Data: 2023</p>
                </div>
                <div class="dictionary-content" id="EconomicResearch">
                    <h4 style="margin-top: 24px;"><a href="https://www.erieri.com/" target="_self">Economic Research
                            Institute (ERI)</a></h4>
                    <p>Geography Type: United States (National & Metro Level)</p>
                    <p>GeoNavigator™ Profile Categories: Labor Cost</p>
                    <p>Data Variables:</p>
                    <ul>
                        <li>
                            Market Wages – The median base salary (annually or hourly) per job title and years of
                            experience [1, 3, 5, and/or 10 years].
                        </li>
                        <li>
                            Wage Growth (Previous Year, 3 Year, & 5 Year) – The percentage change in base salary or
                            salary growth [YOY, 3-year, or 5-year].
                        </li>
                    </ul>
                    <p>Data Release Frequency: Bi-Annually</p>
                    <p>Year of the Data: 2024</p>
                </div>
                <div class="dictionary-content" id="EnvironmentalSystems">
                    <h4 style="margin-top: 24px;"><a href="https://www.esri.com/en-us/home" target="_self">Environmental
                            Systems Research Institute (ESRI)</a></h4>
                    <p>Geography Type: United States (National, Metro Level, Radii, & Drive Time) & Canada (National,
                        Metro Level,Radii, & Drive Time)</p>
                    <p>GeoNavigator™ Profile Categories: Labor Cost, Labor Supply</p>
                    <p>US - Data Variables:</p>
                    <ul>
                        <li>
                            % Earning < $25,000/Year – The five-year estimate of the percentage of households’ incomes,
                                inflation-adjusted dollars to the final year of the five-year range, earning less than
                                $25,000. </li>
                        <li>
                            Housing Affordability - The ratio of the cost of buying a house (median) in comparison to
                            household income (median) in the market area; essentially how much of one's yearly income
                            would be required to buy property.
                        </li>
                        <li>
                            Median Home Value – The five-year estimate of the median cost of a home; half of the homes
                            cost less, and half of the homes cost more than the calculated value.
                        </li>
                        <li>
                            Median Household Income – The five-year estimate of the median annual income for all
                            household earners age 15+; half of the population earn income higher, and half of the
                            population earn less than the calculated value.
                        </li>
                        <li>
                            Simplify or clarify this long sentence: Projected Household Income Growth – A five-year
                            forecast of median household income, represented as a compound annual growth rate (CAGR),
                            indicating positive or negative trends.
                        </li>
                        <li>
                            % FIRE Industry – The percentage of the workforce employed in finance and insurance.
                        </li>
                        <li>
                            % Healthcare Services – The percentage of the workforce employed in providing health care
                            and social assistance for individuals.
                        </li>
                        <li>
                            % Hispanic – The percentage of the population that is Hispanic.
                        </li>
                        <li>
                            % Information Services – The percentage of the workforce employed in producing and
                            distributing information and cultural products, providing the means to transmit or
                            distribute these products as well as data or communications, and/or processing data.
                        </li>
                        <li>
                            % Management - The percentage of the workforce employed in management related occupations.
                        </li>
                        <li>
                            % Manufacturing - The percentage of the workforce employed in the mechanical, physical, or
                            chemical transformation of materials, substances, or components into new products.
                        </li>
                        <li>
                            % Professional, Scientific, & Tech Services – The percentage of the workforce employed in
                            professional, scientific, and technical activities for others; activities performed include:
                            legal advice and representation; accounting, bookkeeping, and payroll services;
                            architectural, engineering, and specialized design services; computer services; consulting
                            services; research services; advertising services; photographic services; translation and
                            interpretation services; veterinary services; and other professional, scientific, and
                            technical services.
                        </li>
                        <li>
                            % Sales and Office - The percentage of the workforce employed in sales and office related
                            occupations.
                        </li>
                        <li>
                            % Service Industry - The percentage of the workforce employed in accommodation or food
                            services, providing customers with lodging and/or preparing meals, snacks, and beverages for
                            immediate consumption.
                        </li>
                        <li>
                            % Spanish Speaking – The percentage of the population that sometimes or always speaks
                            Spanish at home.
                        </li>
                        <li>
                            % Transportation, Warehousing, & Utilities - The percentage of the workforce employed in
                            service-providing supersector groups: wholesale trade, retail trade, transportation and
                            warehousing, and utilities.
                        </li>
                        <li>
                            % Wholesale Trade - The percentage of the workforce employed in wholesaling merchandise,
                            generally without transformation, and rendering services incidental to the sale of
                            merchandise.
                        </li>
                        <li>
                            Average Commute Time – The five-year estimate of the aggregate commute to work in minutes.
                        </li>
                        <li>
                            Employed in Armed Forces
                        </li>
                        <li>
                            Labor Force – The estimate of the civilian population age 16+ in the workforce who are
                            non-miliary, unemployed/employable or employed.
                        </li>
                        <li>
                            Projected Population Growth
                        </li>
                        <li>
                            Targeted Age Group (21-35), (21-44), (65-74), (75-84) & (85+)
                        </li>
                        <li>
                            % Associate’s Degree – The five-year estimate of the percentage of the population age 25+
                            whose highest educational attainment level is an Associate’s Degree.
                        </li>
                        <li>
                            % Bachelor's Degree - The five-year estimate of the percentage of the population age 25+
                            whose highest educational attainment level is a bachelor’s degree.
                        </li>
                        <li>
                            % College Enrollment - The five-year estimate of the percentage of the population age 3+
                            enrolled in college, inclusive of those enrolled in an undergraduate degree or corresponding
                            secondary schools if credit can be obtained from a private or public college (those enrolled
                            in a vocational, technical, or business school program).
                        </li>
                        <li>
                            % Doctoral Degree - The five-year estimate of the percentage of the population age 25+ whose
                            highest educational attainment level is a doctoral degree
                        </li>
                        <li>
                            % High School Graduates - The five-year estimate of the percentage of the population age 25+
                            whose highest educational attainment level is a high school diploma.
                        </li>
                        <li>
                            % Master’s Degree - The five-year estimate of the percentage of the population age 25+ whose
                            highest educational attainment level is a master’s degree.
                        </li>
                        <li>
                            % Some College Experience (no degree) - The five-year estimate of the percentage of the
                            population age 25+ whose highest educational attainment level is Some College/no degree.
                        </li>
                        <li>
                            % Armed Forces – The five-year estimate of the percentage of the population age 18+ who are
                            Veterans but not currently serving on active duty in the U.S. Army, Navy, Air Force, Marine
                            Corps, or the Coast Guard, or who served in the U.S. Merchant Marine during World War II.
                        </li>
                        <li>
                            % Commute 45+ Minutes - The five-year estimate of the percentage of the population age 16+
                            whose commute time to work is 45 or more.
                        </li>
                        <li>
                            % Females Not in Labor Force - The five-year estimate of the percentage of the population of
                            females 20-64 not in the labor force.
                        </li>
                        <li>
                            % Households with Children - The five-year estimate of the percentage of owner-occupied
                            housing units with children under the age of 18.
                        </li>
                        <li>
                            % Work at Home - The five-year estimate of the percentage of workers age 16+ who primarily
                            worked at home (no transportation to work).
                        </li>
                        <li>
                            Average Travel Time - The five-year estimate of the average commute time to work.
                        </li>
                        <li>
                            Potential Retirees – The five-year estimate of the percentage of the population that are
                            potential retirees (aged 65+).
                        </li>
                        <li>
                            Ethnic Diversity Index - The estimated diversity index, summarizes racial and ethnic
                            diversity, indicating the likelihood that two individuals, chosen at random from the same
                            area, belong to the same race or ethnic group.
                        </li>
                    </ul>
                    <p>CAN – Data Variables:</p>
                    <ul>
                        <li>Coming Soon!</li>
                    </ul>
                    <p>Data Release Frequency: Annually</p>
                    <p>Year of the Data: 2020/2023</p>
                    <p>Additional Information: Industries are classified using the <a
                            href="https://www.bls.gov/iag/tgs/iag_index_naics.htm" target="_self">NAICS code system</a>
                        and occupations using the <a href="https://www.bls.gov/soc/home.htm" target="_self">SOC code
                            system.</a></p>
                </div>
                <div class="dictionary-content" id="NationalCenter">
                    <h4 style="margin-top: 24px;">National Center for Education Statistics, Integrated Postsecondary
                        Education Data System (IPEDS)</h4>
                    <p>Geography Type: United States (Per College and Aggregated to the Metro Level)</p>
                    <p>GeoNavigator™ Profile Categories: Labor Supply</p>
                    <p>Data Variables:</p>
                    <ul>
                        <li>
                            Degree Completions Count – The total count of degree completions (Bachelor’s, Associate’s,
                            Master’s and/or Doctoral Degrees).
                        </li>
                        <li>
                            Degree Completions Density – The total count of degree completions (Bachelor’s, Associate’s,
                            Master’s and/or Doctoral Degrees) per 10,000 people.
                        </li>
                        <li>
                            Total 2-Year Enrollment Count – The total count of people enrolled in 2-Year
                            colleges/universities.
                        </li>
                        <li>
                            Total 2-Year Enrollment Density - The total count of people enrolled in 2-Year
                            colleges/universities per 10,000 people.
                        </li>
                        <li>
                            Total 4-Year Enrollment Count - The total count of people enrolled in 4-Year
                            colleges/universities.
                        </li>
                        <li>
                            Total 4-Year Enrollment Density - The total count of people enrolled in 4-Year
                            colleges/universities per 10,000 people.
                        </li>
                    </ul>
                    <p>Data Release Frequency: Annually</p>
                    <p>Year of the Data: 2022/2023, <i>Degree Completions lag about 7 months behind Enrollment</i></p>
                </div>
                <div class="dictionary-content" id="Lightcast">
                    <h4 style="margin-top: 24px;">Lightcast (EMSI + Burning Glass)</h4>
                    <p>Geography Type: United States (National & Metro Level) & Canada (National & Metro Level)</p>
                    <p>GeoNavigator™ Profile Categories: Labor Supply</p>
                    <p>US - Data Variables:</p>
                    <ul>
                        <li>
                            Turnover Rate – The total number of separations divided by the total number of jobs; the
                            turnover rate represents the level of movement within an occupation or group of occupations.
                        </li>
                        <li>
                            Occupational Diversity Count – Total number of people within an occupations group
                            [Back-Office, Contact Center, Finance & Accounting, Headquarters, Human Resources, Insurance
                            Processing, Legal, Life Sciences, Manufacturing, Marketing & Media, Tech - All, Tech –
                            Cyber, Tech – Data Analytics, Tech – Development, Tech – Infrastructure, Warehouse &
                            Distribution] and also part of said diversity group [Age 14-18, Age 19-21, Age 22-24, Age
                            25-34, Age 35-44, Age 45-54, Age 55-64, Age 65+, Males, Females, White, Black and African
                            American, American Indian or Alaska Native, Asian, Native Hawaiian or Other Pacific
                            Islander, Two or More Races, Hispanic or Latino, Non-White].
                        </li>
                        <li>
                            Occupational Diversity Density - Total number of people within an occupations group
                            [Back-Office, Contact Center, Finance & Accounting, Headquarters, Human Resources, Insurance
                            Processing, Legal, Life Sciences, Manufacturing, Marketing & Media, Tech - All, Tech –
                            Cyber, Tech – Data Analytics, Tech – Development, Tech – Infrastructure, Warehouse &
                            Distribution] who are part of the following diversity group [Age 14-18, Age 19-21, Age
                            22-24, Age 25-34, Age 35-44, Age 45-54, Age 55-64, Age 65+, Males, Females, White, Black and
                            African American, American Indian or Alaska Native, Asian, Native Hawaiian or Other Pacific
                            Islander, Two or More Races, Hispanic or Latino, Non-White] per 10,000 workers.
                        </li>
                    </ul>
                    <p>CAN – Data Variables:</p>
                    <ul>
                        <li>
                            Degree Completions Count - The total count of degree completions (Bachelor’s, Associate’s,
                            Master’s and/or Doctoral Degrees).
                        </li>
                        <li>
                            Degree Completions Density - The total count of degree completions (Bachelor’s, Associate’s,
                            Master’s and/or Doctoral Degrees) per 10,000 people.
                        </li>
                        <li>
                            Total 2-Year Enrollment Count - The total count of people enrolled in 2-Year
                            colleges/universities.
                        </li>
                        <li>
                            Total 2-Year Enrollment Density - The total count of people enrolled in 2-Year
                            colleges/universities per 10,000 people.
                        </li>
                        <li>
                            Total 4-Year Enrollment Count - The total count of people enrolled in 4-Year
                            colleges/universities.
                        </li>
                        <li>
                            Total 4-Year Enrollment Density - The total count of people enrolled in 4-Year
                            colleges/universities per 10,000 people.
                        </li>
                    </ul>
                    <p>Data Release Frequency: Annually</p>
                    <p>Year of the Data: 2023</p>
                </div>
                <div class="dictionary-content" id="LinkedInTalent">
                    <h4 style="margin-top: 24px;">LinkedIn Talent Insights (LTI)</h4>
                    <p>Geography Type: United States (National & Metro Level) & Canada (National & Metro Level)</p>
                    <p>GeoNavigator™ Profile Categories: Labor Supply, Labor Demand, Competition, Diversity, and
                        Inclusion</p>
                    <p>US - Data Variables:</p>
                    <ul>
                        <li>
                            Skills - Professionals Count – The count of professionals (profiles/users) with the
                            following skills [AI, C, C#, C++, Cloud Computing, Cybersecurity, Data Science, Java,
                            Machine Learning, Python, R, Ruby, Ruby on Rails, SQL, UI/UX].
                        </li>
                        <li>
                            Skills - Professionals Density The count of professionals (profiles/users) per 10,000 users
                            with the following skills [I, C, C#, C++, Cloud Computing, Cybersecurity, Data Science,
                            Java, Machine Learning, Python, R, Ruby, Ruby on Rails, SQL, UI/UX].
                        </li>
                        <li>
                            Skills - Professionals 1 Year Growth – The 1-year growth of professionals (LinkedIn users)
                            with the following skills [AI, C, C#, C++, Cloud Computing, Cybersecurity, Data Science,
                            Java, Machine Learning, Python, R, Ruby, Ruby on Rails, SQL, UI/UX].
                        </li>
                        <li>
                            Job Posts - The count of job postings with the following skills [AI, C, C#, C++, Cloud
                            Computing, Cybersecurity, Data Science, Java, Machine Learning, Python, R, Ruby, Ruby on
                            Rails, SQL, UI/UX].
                        </li>
                        <li>
                            Professionals Per Job Post (Skills) - The count of professionals (profiles/users) per job
                            post with the following skills [AI, C, C#, C++, Cloud Computing, Cybersecurity, Data
                            Science, Java, Machine Learning, Python, R, Ruby, Ruby on Rails, SQL, UI/UX].
                        </li>
                        <li>
                            Professionals Per Job Post (Functions) - The count of professionals (profiles/users) per job
                            post working in the following functions [Accounting, Administrative, Business Development,
                            Engineering, Finance, Human Resources, Information Technology, Legal, Marketing, Real
                            Estate. Sales, Support].
                        </li>
                        <li>
                            Gender Gap - The ratio of males to females (profiles/users) with the following skills [AI,
                            C, C#, C++, Cloud Computing, Cybersecurity, Data Science, Java, Machine Learning, Python, R,
                            Ruby, Ruby on Rails, SQL, UI/UX].
                        </li>
                    </ul>

                    <p>CAN – Data Variables:</p>
                    <ul>
                        <li>Coming Soon!</li>
                    </ul>

                    <p>Data Release Frequency: Real Time</p>
                    <p>Year of the Data: 2024</p>
                </div>
                <div class="dictionary-content" id="LaborPlan">
                    <h4 style="margin-top: 24px;">LaborPlan</h4>
                    <p>LaborPlan is a predictive model using current labor data to estimate the long-term favorability
                        of a market based on specific <b>scenario conditions</b> (occupation, location, wage, client
                        turnover,
                        industry turnover, competitor size, and client size) and <b>labor market inputs</b> (current
                        employee
                        count, annual growth rate, average hourly wage, annual turnover, hire-to-interview ratio, market
                        turnover, and competitor turnover).</p>
                    <ul>
                        <li>Labor Force/Applicant Pool – Environmental Systems Research Institute (ESRI), Canada
                            (National, Metro Level, Radii, & Drive Time)</li>
                        <li>Occupational Wages – Bureau of Labor Statistics (BLS)</li>
                        <li>Unemployment Rate – Bureau of Labor Statistics (BLS)</li>
                        <li>Industry Turnover Rate - Bureau of Labor Statistics (BLS)</li>
                    </ul>
                    <p>For any additional questions please reach out to Kathleen Cummings - <a
                            href="mailto:Kathleen.Cummings@cbre.com">Kathleen.Cummings@cbre.com</a>.</p>
                </div>
            </div>
        </div>
        <div class="right-side-panel">
            <div class="right-panel-content">
                <div>
                    <div class="url-section-heading">On this page</div>
                    <div class="page-section">
                        <div class="page-url-section">
                            <a #url id="BureauLaborClick" class="page-url selected"
                                (click)="onClick('BureauLabor')">Bureau of Labor Statistics (BLS)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="EasyAnalyticsClick" class="page-url selected"
                                (click)="onClick('EasyAnalytics')">Easy Analytics Software Inc (EASI)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="EconomicResearchClick" class="page-url selected"
                                (click)="onClick('EconomicResearch')">Economic Research Institute (ERI)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="EnvironmentalSystemsClick" class="page-url selected"
                                (click)="onClick('EnvironmentalSystems')">Environmental Systems Research Institute
                                (ESRI)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="NationalCenterClick" class="page-url selected"
                                (click)="onClick('NationalCenter')">National Center for Education Statistics, Integrated
                                Postsecondary Education Data System (IPEDS)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="LightcastClick" class="page-url selected"
                                (click)="onClick('Lightcast')">Lightcast (EMSI + Burning Glass)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="LinkedInTalentClick" class="page-url selected"
                                (click)="onClick('LinkedInTalent')">LinkedIn Talent Insights (LTI)</a>
                        </div>
                        <div class="page-url-section">
                            <a #url id="LaborPlantClick" class="page-url selected"
                                (click)="onClick('LaborPlan')">LaborPlan</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>