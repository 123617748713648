<h2 mat-dialog-title>Add New Company</h2>
<mat-dialog-content>
    <p>Add the company name and a industry.</p>
    <form [formGroup]="companyForm">
        <div class="row">
            <div class="col-6">
                <lag-text [istextarea]="false"
                [maxLength]="200" class="matFormField lag-text-input company-name" [ngClass]="{ 'error': isDuplicate }"
                    [field]="reportMetadata.companyName"></lag-text>
            </div>
            <div class="col-6 d-flex align-items-end">
                <lag-single-select class="matFormField w-100 company-industry" [field]="reportMetadata.industry"></lag-single-select>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end company-buttons">
    <button mat-button mat-dialog-close color="secondary" class="cansel">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>