<div id="landing-page">
  <div class="landing-header-container">
    <div class="message-container">
      <p>{{ currentDate }}</p>
      <p>Welcome back, {{ firstName }}</p>
    </div>
    <div class="newletter-container">
      <p class="title">
        <mat-icon svgIcon="article" class="white-fill" style="width: 32px; height: 32px;"></mat-icon>
        CBRE GeoNavigator<sup>&trade;</sup> Weekly Newsletter</p>
        <p class="subtitle">Subscribe today</p>
        <p class="description">Stay up to date on the latest news from the labor market by signing up for the CBRE GeoNavigator<sup>&trade;</sup> Weekly Market Summary Newsletter.</p>
        <button *ngIf="isSubscribed" class="button primary primary-theme-2" (click)="unsubscribe()" >Unsubscribe</button>
        <button *ngIf="!isSubscribed" class="button primary primary-theme-2" (click)="openNewsletterDialog()">Subscribe</button>
    </div>
  </div>
  <div class="landing-content">
    <div class="mini-infograph-container">
      <div class="mini-infographs">
        <div class="mini-infograph">
          <lag-high-charts *ngIf="employmentyoyOptions" [options]="employmentyoyOptions"></lag-high-charts>
          <ng-container *ngIf="!employmentyoyOptions"><ng-container *ngTemplateOutlet="miniInfographColumn"></ng-container></ng-container>
        </div>
        <div class="mini-infograph">
          <lag-high-charts *ngIf="unemploymentkpiOptions" [options]="unemploymentkpiOptions"></lag-high-charts>
          <ng-container *ngIf="!unemploymentkpiOptions"><ng-container *ngTemplateOutlet="miniInfographLine"></ng-container></ng-container>
        </div>
        <div class="mini-infograph">
          <lag-high-charts *ngIf="inflationkpiOptions" [options]="inflationkpiOptions"></lag-high-charts>
          <ng-container *ngIf="!inflationkpiOptions"><ng-container *ngTemplateOutlet="miniInfographLine"></ng-container></ng-container>
        </div>
        <div class="mini-infograph">
          <lag-high-charts *ngIf="layoffsOptions" [options]="layoffsOptions"></lag-high-charts>
          <ng-container *ngIf="!layoffsOptions"><ng-container *ngTemplateOutlet="miniInfographLine"></ng-container></ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="hasArticleAccess">
      <app-globe [articleList]="articles"></app-globe>
    </div>
    <div class="widget-container">
      <div class="statistics-col">
        <p class="title">National labor statistics</p>
        <hr class="divider" />
        <div class="widget-list">
          <div class="widget-block line-chart">
            <form [formGroup]="unemploymentForm">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="title mb-1">Unemployment rate</p>
                  <p class="subtitle m-0" style="color: #767676;">
                    Last 5 years, seasonally adjusted
                  </p>
                </div>
                <mat-radio-group class="filter-date-container" class="dropdown-row" (change)="handleUnemployementChange($event)">
                  <div class="d-flex align-items-center">
                    <mat-radio-button disableRipple [value]="'cbsa'" [checked]="unemploymentFilter === 'cbsa'"></mat-radio-button>
                    <lag-text-multiple-autocomplete class="matFormField" [panelWidth]="250" style="width: 250px" [disabled]="unemploymentFilter !== 'cbsa'" [field]="unemploymentFields.cbsa" ></lag-text-multiple-autocomplete>
                  </div>
                  <div class="d-flex align-items-center">
                    <mat-radio-button disableRipple [value]="'state'" [checked]="unemploymentFilter === 'state'"></mat-radio-button>
                    <lag-text-multiple-autocomplete class="matFormField" [disabled]="unemploymentFilter !== 'state'" [field]="unemploymentFields.state" ></lag-text-multiple-autocomplete>
                  </div>
                  </mat-radio-group>
            </div>
            <lag-high-charts *ngIf="unemploymentOptions" [options]="unemploymentOptions"></lag-high-charts>
            <ng-container *ngIf="!unemploymentOptions"><ng-container *ngTemplateOutlet="infographLine1"></ng-container></ng-container>
            </form>
          </div>
          <div class="widget-block line-chart">
            <form [formGroup]="wageForm">
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="title mb-1">Wage trends</p>
                    <p class="subtitle m-0" style="color: #767676;">Last 5 years</p>
                  </div>
                  <span class="dropdown-row">
                    <lag-text-multiple-autocomplete class="matFormField" style="width: 250px" [field]="wageFields.industry" ></lag-text-multiple-autocomplete>
                    <lag-single-select class="matFormField" [field]="wageFields.experience" ></lag-single-select>
                </span>
                </div>
            </form>
            <lag-high-charts *ngIf="wageOptions" [options]="wageOptions"></lag-high-charts>
            <ng-container *ngIf="!wageOptions"><ng-container *ngTemplateOutlet="infographLine2"></ng-container></ng-container>
          </div>
        </div>
      </div>
      <div class="news-col">
        <p class="title">Insights & Research</p>
        <hr class="divider" />
        <div class="widget-list">
          <div class="widget-block">
            <div class="news-ul">
              <ng-container *ngIf="!thoughtLeadership">
                <ng-container *ngTemplateOutlet="articleLoader"></ng-container>
                <ng-container *ngTemplateOutlet="articleLoader"></ng-container>
                <ng-container *ngTemplateOutlet="articleLoader"></ng-container>
                <ng-container *ngTemplateOutlet="articleLoader"></ng-container>
                <ng-container *ngTemplateOutlet="articleLoader"></ng-container>
              </ng-container>
              <div *ngFor="let article of thoughtLeadership | slice:0:5" class="news-li">
                <div class="image-container">
                  <img *ngIf="article.articleImageUrl" [src]="article.articleImageUrl" />
                </div>
                <div>
                  <p class="news-category">{{ article.source.toUpperCase() != articleSource ? article.industry : articleIndustry }}</p>
                  <a href="{{article.url}}" target="_blank" class="hyperlink news-title">{{article.title}}</a>
                  <p class="news-subtitle">{{article.source}} | {{ article.datePublished | date: 'fullDate'}}</p> 
                </div>
              </div>
              <a *ngIf="hasArticleAccess" class="hyperlink view-all-link" [routerLink]="['/articles']">View all articles</a>
            </div>
          </div>
          <div class="connect-widget">
            <p>Connect with a CBRE Location Strategy Consultant</p>
            <div class="connect-widget-footer">
              <span (click)="openInterestModal()">Contact a team member</span>
              <lag-icon iconName="arrow_forward_ios" iconColor="rgb(67, 82, 84)" style="scale: 0.75;"></lag-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #interestModal let-data>
  <app-interest [closeModal]="data.closeDialog"></app-interest>
</ng-template>

<ng-template #miniInfographColumn>
  <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
  <div class="d-flex" [ngStyle]="{marginBottom: '24px'}">
    <ngx-skeleton-loader
    [theme]="{
      height: '40px',
      width: '40px',
      marginRight: '16px',
      marginTop: '10px'
    }" appearance="circle"></ngx-skeleton-loader>
    <ngx-skeleton-loader
    [theme]="{
      width: '131px',
      height: '50px',
      'margin-bottom': '10px'
    }"
  ></ngx-skeleton-loader>
  </div>
  <lag-graph-loader graphType="column1" [height]="55"></lag-graph-loader>
  <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
</ng-template>

<ng-template #miniInfographLine>
    <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
    <div class="d-flex" [ngStyle]="{marginBottom: '24px'}">
      <ngx-skeleton-loader
      [theme]="{
        height: '40px',
        width: '40px',
        marginRight: '16px',
        marginTop: '10px'
      }" appearance="circle"></ngx-skeleton-loader>
      <ngx-skeleton-loader
      [theme]="{
        width: '131px',
        height: '50px',
        'margin-bottom': '10px'
      }"
    ></ngx-skeleton-loader>
    </div>
    <lag-graph-loader graphType="line3" [height]="55"></lag-graph-loader>
    <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
</ng-template>

<ng-template #infographLine1>
  <div class="widget-block">
    <div class="d-flex justify-content-between" [ngStyle]="{marginBottom: '24px'}">
      <div>
        <ngx-skeleton-loader
        [theme]="{
          width: '200px',
          height: '26px'
        }"></ngx-skeleton-loader>
        <ngx-skeleton-loader
        [theme]="{
          width: '147px',
          height: '17px'
        }"></ngx-skeleton-loader>

      </div>
      <div class="d-flex">
        <ngx-skeleton-loader
        [theme]="{
          width: '178px',
          height: '37px',
          marginRight: '16px'
        }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
        [theme]="{
          width: '178px',
          height: '37px'
        }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <lag-graph-loader graphType="line1"></lag-graph-loader>
  </div>
</ng-template>
<ng-template #infographLine2>
  <div class="widget-block">
    <div class="d-flex justify-content-between" [ngStyle]="{marginBottom: '24px'}">
      <div>
        <ngx-skeleton-loader
        [theme]="{
          width: '200px',
          height: '26px'
        }"></ngx-skeleton-loader>
        <ngx-skeleton-loader
        [theme]="{
          width: '147px',
          height: '17px'
        }"></ngx-skeleton-loader>

      </div>
      <div class="d-flex">
        <ngx-skeleton-loader
        [theme]="{
          width: '178px',
          height: '37px',
          marginRight: '16px'
        }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
        [theme]="{
          width: '178px',
          height: '37px'
        }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <lag-graph-loader graphType="line2"></lag-graph-loader>
  </div>
</ng-template>

<ng-template #articleLoader>
  <div class="d-flex gap-2">
    <ngx-skeleton-loader
    [theme]="{
      width: '100px',
      height: '100px',
      borderRadius: 0
    }"
    ></ngx-skeleton-loader>
  <div>
    <div class="d-flex flex-column">
      <ngx-skeleton-loader
      [theme]="{
        width: '70px',
        height: '12px'
      }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
      [theme]="{
        width: '268px',
        height: '15px'
      }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
      [theme]="{
        width: '268px',
        height: '15px'
      }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
      [theme]="{
        width: '243px',
        height: '15px'
      }"
      ></ngx-skeleton-loader>
    </div>
    </div>
  </div>
</ng-template>