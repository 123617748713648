import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
// import { NewDTReportComponent } from './drive-time/report/report.component';
import { SupportComponent } from './components/support/support.component';
import { AppErrorComponent } from './core/error/error.component';
import { NoAccessRedirectComponent } from './core/no-access-redirect/no-access-redirect.component';
import { NoAccessComponent } from '@core/noaccess/no-access.component';
import { CartoComponent } from './drive-time/research/carto/carto.component';
import { LandingComponent } from './drive-time/landing/landing.component';
import { ParamsComponent } from './drive-time/params/parameters.component';
import { SingleProjectInfoComponent } from './drive-time/params/single-project-info/single-project-info.component';
import { ParametersResolverService } from './drive-time/resolvers/parameters-resolver.service';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
import { LogoutComponent } from '@core/logout/logout.component';
import { RoleGuard } from './guards/role.guard';
import { AccessGuard } from './guards/access.guard';

import { competitionRoutesMapping } from './drive-time/research/competition/competition-routing.module';
import { profileWizardRoutesMapping } from './drive-time/profile-wizard/profile-wizard-routing.module';
import { benchMarkingRoutesMapping } from './drive-time/bench-marking/bench-marking-routing.module';
import { visualizeRoutesMapping } from './drive-time/visualize/visualize-routing.module';
import { MainComponent } from './drive-time/main/main.component';
import { AccessPermission, FeatureAccessItemEnum } from './models/userProfile.model';
import { DataDictionaryComponent } from './drive-time/resources/data-dictionary/data-dictionary.component';

const HOME = 'home';
export const appRoutesMapping: Record<string, string> = {
  SINGLE_SITE_INFO: 'single-site/project-info',
  SINGLE_SITE_HOME: 'single-site/home',
  SINGLE_SITE: 'single-site',
  MULTI_SITE: 'multi-site',
  BENCHMARK: 'benchmark',
  PROFILE: 'profile',
  MY_PROFILE: 'my-profile',
  DATA_EXPLORER: 'data-explorer',
  VISUALIZE: 'visualize',
  COMPETITION: 'competition',
  ARTICLES: 'articles',
  LABOR_PLAN: 'labor-plan',
  INTERVIEWS: 'interviews',
  GEOSKILL: 'geoskill',
  SITE_MANAGEMENT: 'site-management',
  RESOURCES: 'resources',
  DISCLAIMER: 'Disclaimer',
  ERROR: 'Error',
  SUPPORT: 'Support',
  LOGOUT: 'logout',
  LOGIN_CALLBACK: 'login/callback',
  NO_ACCESS: 'NoAccess',
  REQUEST_ACCESS: 'request-access',
  SITE_SELECTION: 'site-selection',
  LANDING: '',
  MARKET_REPORT: 'market-report',
  DATA_DICTIONARY: 'data-dictionary',
};

appRoutesMapping.SEARCH_COMPETITION = `${appRoutesMapping.COMPETITION}/${competitionRoutesMapping.SEARCH_COMPETITION}`;
appRoutesMapping.CREATE_PROFILE_WIZARD = `${appRoutesMapping.PROFILE}/${profileWizardRoutesMapping.CREATE_PROFILE}`;
appRoutesMapping.EDIT_PROFILE_WIZARD = `${appRoutesMapping.PROFILE}/${profileWizardRoutesMapping.EDIT_PROFILES}`;
appRoutesMapping.MANAGE_PROFILE_WIZARD = `${appRoutesMapping.PROFILE}/${profileWizardRoutesMapping.MANAGE_PROFILES}`;
appRoutesMapping.BENCHMARK_HOME = `${appRoutesMapping.BENCHMARK}/${benchMarkingRoutesMapping.HOME}`;
appRoutesMapping.DATA_EXPLORER_HOME = `${appRoutesMapping.DATA_EXPLORER}/${HOME}`;
appRoutesMapping.VISUALIZE_THOUGHT_SPOT = `${appRoutesMapping.VISUALIZE}/${visualizeRoutesMapping.THOUGHT_SPOT}`;


const appRoutes: Routes = [
  {
    path: appRoutesMapping.SINGLE_SITE,
    redirectTo: appRoutesMapping.SINGLE_SITE_INFO,
  },
  {
    // this route is used for PDF generation of market reports, route access by node js functions
    path: 'emerald-report',
    loadChildren: () => import('./drive-time/emerald-report/emerald-report.module').then((m) => m.EmeraldReportModule),
  },
  {
    path: appRoutesMapping.REQUEST_ACCESS,
    component: NoAccessComponent,
    canActivate: [OktaAuthGuard],
  },
  { path: appRoutesMapping.LOGIN_CALLBACK, component: OktaCallbackComponent },
  { path: appRoutesMapping.LOGOUT, component: LogoutComponent },
  { path: appRoutesMapping.SUPPORT, component: SupportComponent, canActivate: [OktaAuthGuard] },
  { path: appRoutesMapping.NO_ACCESS, component: NoAccessRedirectComponent },
  { path: appRoutesMapping.ERROR, component: AppErrorComponent, canActivate: [OktaAuthGuard] },
  {
    path: '',
    canActivate: [AccessGuard],
    component: MainComponent,
    children: [
      {
        path: appRoutesMapping.SINGLE_SITE_INFO,
        component: SingleProjectInfoComponent,
      },
      {
        path: appRoutesMapping.SINGLE_SITE_HOME,
        component: ParamsComponent,
      },
      {
        path: appRoutesMapping.MULTI_SITE,
        loadChildren: () => import('./drive-time/multi-site/multi-site.module').then((m) => m.MultiSiteModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.BENCHMARK,
        loadChildren: () => import('./drive-time/bench-marking/bench-marking.module').then((m) => m.BenchMarkingModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.PROFILE,
        loadChildren: () => import('./drive-time/profile-wizard/profile-wizard.module').then((m) => m.ProfileWizardModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.MY_PROFILE,
        loadChildren: () => import('./drive-time/user-profile/user-profile.module').then((m) => m.UserProfileModule),
      },
      {
        path: appRoutesMapping.DATA_EXPLORER,
        loadChildren: () => import('./drive-time/data-explorer/data-explorer.module').then((m) => m.DataExplorerModule),
      },
      {
        path: appRoutesMapping.VISUALIZE,
        loadChildren: () => import('./drive-time/visualize/visualize.module').then((m) => m.VisualizeModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.COMPETITION,
        loadChildren: () => import('./drive-time/research/competition/competition.module').then((m) => m.CompetitionModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.ARTICLES,
        loadChildren: () => import('./drive-time/research/article/article.module').then((m) => m.ArticleModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.LANDING,
        loadChildren: () => import('./drive-time/landing/landing.module').then((m) => m.LandingModule),
        component: LandingComponent,
      },
      {
        path: '',
        loadChildren: () => import('./drive-time/landing/landing.module').then((m) => m.LandingModule),
        component: LandingComponent,
      },
      {
        path: appRoutesMapping.GEOSKILL,
        loadChildren: () => import('./drive-time/geoskill/geoskill.module').then((m) => m.GeoskillModule),
      },
      {
        path: appRoutesMapping.MARKET_REPORT,
        loadChildren: () => import('./drive-time/marketreport/marketreport.module').then((m) => m.MarketreportModule),
      },
      {
        path: appRoutesMapping.LABOR_PLAN,
        loadChildren: () => import('./drive-time/research/laborplan/laborplan.module').then((m) => m.LaborplanModule),
        canActivate: [RoleGuard],
        data:{
          guadAccessItem: FeatureAccessItemEnum.LaborPlan
        }
      },
      {
        path: appRoutesMapping.INTERVIEWS,
        loadChildren: () => import('./drive-time/research/interviews/interviews.module').then((m) => m.InterviewsModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.SITE_MANAGEMENT,
        loadChildren: () =>
          import('./drive-time/site-management/site-management.module').then((m) => m.SiteManagementModule),
        canActivate: [RoleGuard],
      },
      {
        path: appRoutesMapping.RESOURCES,
        loadChildren: () => import('./drive-time/resources/resources.module').then((m) => m.ResourcesModule),
      },
      {
        path: appRoutesMapping.DISCLAIMER,
        component: DisclaimerComponent,
      },
      {
        path: appRoutesMapping.SITE_SELECTION,
        component: CartoComponent,
      },
      {
        path: appRoutesMapping.DATA_DICTIONARY,
        component: DataDictionaryComponent,
      },
    ]
  },
  // { path: '', redirectTo: 'multi-site/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'Login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
      enableTracing: false,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
