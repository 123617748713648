import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-data-dictionary',
  templateUrl: './data-dictionary.component.html',
  styleUrls: ['./data-dictionary.component.scss']
})
export class DataDictionaryComponent implements OnInit {

  @ViewChildren('url') url:QueryList<ElementRef>;

  constructor() { }

  ngOnInit(): void {
  }

  onClick(elementId: string): void { 
    this.url.forEach(element=>element.nativeElement.classList.remove('selected'));
      document.getElementById(elementId).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
      document.getElementById(`${elementId}Click`).classList.add('selected');
  }
}
