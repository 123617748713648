import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@core/snackBar.notification.service';
import { DriveTimeService } from '@services/drivetime/drivetime.service';

@Component({
  selector: 'app-new-company-dialog',
  templateUrl: './new-company-dialog.component.html',
  styleUrls: ['./new-company-dialog.component.scss']
})
export class NewCompanyDialogComponent implements OnInit {
  companyForm: FormGroup;
  industries = [];
  reportMetadata = {
    companyName: {
      label: 'Company name (required)',
      control: 'companyName',
      requireSelection: true,
    },
    industry: {
      label: 'Industry (required)',
      control: 'industry',
      options: [],
      requireSelection: true,
    }
  };
  isDuplicate = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NewCompanyDialogComponent>,
    private driveTimeService: DriveTimeService,
    private snackBarService: SnackbarService) { }

  ngOnInit(): void {
    this.initForm();

    this.driveTimeService.getStaticIndustries().subscribe((interviewStaticData) => {
      this.reportMetadata.industry = {
        ...this.reportMetadata.industry,
        options: interviewStaticData.sort((a,b) => a.name.localeCompare(b.name))
      };
    })
  }

  initForm() {
    this.companyForm = this.fb.group({
      companyName: [null, Validators.required],
      industry: [null, Validators.required],
    });
  }

  onSave(): void {
    if (!this.companyForm.valid) {

      this.companyForm.markAllAsTouched()
      return
    }

      this.driveTimeService.createClient({ name: this.companyForm.value.companyName, industryId: this.companyForm.value.industry }).subscribe(item => {
        this.isDuplicate = false;
        this.snackBarService.showSuccess("You've successfully added a new company.");
        this.dialogRef.close(item);
      }, (error) => {
        this.snackBarService.showError('This company cannot be added because it already exists in our system.');
        this.isDuplicate = true;
      })
  }
}
